(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.COBI = f()}})(function(){var define,module,exports;return (function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

var objectCreate = Object.create || objectCreatePolyfill
var objectKeys = Object.keys || objectKeysPolyfill
var bind = Function.prototype.bind || functionBindPolyfill

function EventEmitter() {
  if (!this._events || !Object.prototype.hasOwnProperty.call(this, '_events')) {
    this._events = objectCreate(null);
    this._eventsCount = 0;
  }

  this._maxListeners = this._maxListeners || undefined;
}
module.exports = EventEmitter;

// Backwards-compat with node 0.10.x
EventEmitter.EventEmitter = EventEmitter;

EventEmitter.prototype._events = undefined;
EventEmitter.prototype._maxListeners = undefined;

// By default EventEmitters will print a warning if more than 10 listeners are
// added to it. This is a useful default which helps finding memory leaks.
var defaultMaxListeners = 10;

var hasDefineProperty;
try {
  var o = {};
  if (Object.defineProperty) Object.defineProperty(o, 'x', { value: 0 });
  hasDefineProperty = o.x === 0;
} catch (err) { hasDefineProperty = false }
if (hasDefineProperty) {
  Object.defineProperty(EventEmitter, 'defaultMaxListeners', {
    enumerable: true,
    get: function() {
      return defaultMaxListeners;
    },
    set: function(arg) {
      // check whether the input is a positive number (whose value is zero or
      // greater and not a NaN).
      if (typeof arg !== 'number' || arg < 0 || arg !== arg)
        throw new TypeError('"defaultMaxListeners" must be a positive number');
      defaultMaxListeners = arg;
    }
  });
} else {
  EventEmitter.defaultMaxListeners = defaultMaxListeners;
}

// Obviously not all Emitters should be limited to 10. This function allows
// that to be increased. Set to zero for unlimited.
EventEmitter.prototype.setMaxListeners = function setMaxListeners(n) {
  if (typeof n !== 'number' || n < 0 || isNaN(n))
    throw new TypeError('"n" argument must be a positive number');
  this._maxListeners = n;
  return this;
};

function $getMaxListeners(that) {
  if (that._maxListeners === undefined)
    return EventEmitter.defaultMaxListeners;
  return that._maxListeners;
}

EventEmitter.prototype.getMaxListeners = function getMaxListeners() {
  return $getMaxListeners(this);
};

// These standalone emit* functions are used to optimize calling of event
// handlers for fast cases because emit() itself often has a variable number of
// arguments and can be deoptimized because of that. These functions always have
// the same number of arguments and thus do not get deoptimized, so the code
// inside them can execute faster.
function emitNone(handler, isFn, self) {
  if (isFn)
    handler.call(self);
  else {
    var len = handler.length;
    var listeners = arrayClone(handler, len);
    for (var i = 0; i < len; ++i)
      listeners[i].call(self);
  }
}
function emitOne(handler, isFn, self, arg1) {
  if (isFn)
    handler.call(self, arg1);
  else {
    var len = handler.length;
    var listeners = arrayClone(handler, len);
    for (var i = 0; i < len; ++i)
      listeners[i].call(self, arg1);
  }
}
function emitTwo(handler, isFn, self, arg1, arg2) {
  if (isFn)
    handler.call(self, arg1, arg2);
  else {
    var len = handler.length;
    var listeners = arrayClone(handler, len);
    for (var i = 0; i < len; ++i)
      listeners[i].call(self, arg1, arg2);
  }
}
function emitThree(handler, isFn, self, arg1, arg2, arg3) {
  if (isFn)
    handler.call(self, arg1, arg2, arg3);
  else {
    var len = handler.length;
    var listeners = arrayClone(handler, len);
    for (var i = 0; i < len; ++i)
      listeners[i].call(self, arg1, arg2, arg3);
  }
}

function emitMany(handler, isFn, self, args) {
  if (isFn)
    handler.apply(self, args);
  else {
    var len = handler.length;
    var listeners = arrayClone(handler, len);
    for (var i = 0; i < len; ++i)
      listeners[i].apply(self, args);
  }
}

EventEmitter.prototype.emit = function emit(type) {
  var er, handler, len, args, i, events;
  var doError = (type === 'error');

  events = this._events;
  if (events)
    doError = (doError && events.error == null);
  else if (!doError)
    return false;

  // If there is no 'error' event listener then throw.
  if (doError) {
    if (arguments.length > 1)
      er = arguments[1];
    if (er instanceof Error) {
      throw er; // Unhandled 'error' event
    } else {
      // At least give some kind of context to the user
      var err = new Error('Unhandled "error" event. (' + er + ')');
      err.context = er;
      throw err;
    }
    return false;
  }

  handler = events[type];

  if (!handler)
    return false;

  var isFn = typeof handler === 'function';
  len = arguments.length;
  switch (len) {
      // fast cases
    case 1:
      emitNone(handler, isFn, this);
      break;
    case 2:
      emitOne(handler, isFn, this, arguments[1]);
      break;
    case 3:
      emitTwo(handler, isFn, this, arguments[1], arguments[2]);
      break;
    case 4:
      emitThree(handler, isFn, this, arguments[1], arguments[2], arguments[3]);
      break;
      // slower
    default:
      args = new Array(len - 1);
      for (i = 1; i < len; i++)
        args[i - 1] = arguments[i];
      emitMany(handler, isFn, this, args);
  }

  return true;
};

function _addListener(target, type, listener, prepend) {
  var m;
  var events;
  var existing;

  if (typeof listener !== 'function')
    throw new TypeError('"listener" argument must be a function');

  events = target._events;
  if (!events) {
    events = target._events = objectCreate(null);
    target._eventsCount = 0;
  } else {
    // To avoid recursion in the case that type === "newListener"! Before
    // adding it to the listeners, first emit "newListener".
    if (events.newListener) {
      target.emit('newListener', type,
          listener.listener ? listener.listener : listener);

      // Re-assign `events` because a newListener handler could have caused the
      // this._events to be assigned to a new object
      events = target._events;
    }
    existing = events[type];
  }

  if (!existing) {
    // Optimize the case of one listener. Don't need the extra array object.
    existing = events[type] = listener;
    ++target._eventsCount;
  } else {
    if (typeof existing === 'function') {
      // Adding the second element, need to change to array.
      existing = events[type] =
          prepend ? [listener, existing] : [existing, listener];
    } else {
      // If we've already got an array, just append.
      if (prepend) {
        existing.unshift(listener);
      } else {
        existing.push(listener);
      }
    }

    // Check for listener leak
    if (!existing.warned) {
      m = $getMaxListeners(target);
      if (m && m > 0 && existing.length > m) {
        existing.warned = true;
        var w = new Error('Possible EventEmitter memory leak detected. ' +
            existing.length + ' "' + String(type) + '" listeners ' +
            'added. Use emitter.setMaxListeners() to ' +
            'increase limit.');
        w.name = 'MaxListenersExceededWarning';
        w.emitter = target;
        w.type = type;
        w.count = existing.length;
        if (typeof console === 'object' && console.warn) {
          console.warn('%s: %s', w.name, w.message);
        }
      }
    }
  }

  return target;
}

EventEmitter.prototype.addListener = function addListener(type, listener) {
  return _addListener(this, type, listener, false);
};

EventEmitter.prototype.on = EventEmitter.prototype.addListener;

EventEmitter.prototype.prependListener =
    function prependListener(type, listener) {
      return _addListener(this, type, listener, true);
    };

function onceWrapper() {
  if (!this.fired) {
    this.target.removeListener(this.type, this.wrapFn);
    this.fired = true;
    switch (arguments.length) {
      case 0:
        return this.listener.call(this.target);
      case 1:
        return this.listener.call(this.target, arguments[0]);
      case 2:
        return this.listener.call(this.target, arguments[0], arguments[1]);
      case 3:
        return this.listener.call(this.target, arguments[0], arguments[1],
            arguments[2]);
      default:
        var args = new Array(arguments.length);
        for (var i = 0; i < args.length; ++i)
          args[i] = arguments[i];
        this.listener.apply(this.target, args);
    }
  }
}

function _onceWrap(target, type, listener) {
  var state = { fired: false, wrapFn: undefined, target: target, type: type, listener: listener };
  var wrapped = bind.call(onceWrapper, state);
  wrapped.listener = listener;
  state.wrapFn = wrapped;
  return wrapped;
}

EventEmitter.prototype.once = function once(type, listener) {
  if (typeof listener !== 'function')
    throw new TypeError('"listener" argument must be a function');
  this.on(type, _onceWrap(this, type, listener));
  return this;
};

EventEmitter.prototype.prependOnceListener =
    function prependOnceListener(type, listener) {
      if (typeof listener !== 'function')
        throw new TypeError('"listener" argument must be a function');
      this.prependListener(type, _onceWrap(this, type, listener));
      return this;
    };

// Emits a 'removeListener' event if and only if the listener was removed.
EventEmitter.prototype.removeListener =
    function removeListener(type, listener) {
      var list, events, position, i, originalListener;

      if (typeof listener !== 'function')
        throw new TypeError('"listener" argument must be a function');

      events = this._events;
      if (!events)
        return this;

      list = events[type];
      if (!list)
        return this;

      if (list === listener || list.listener === listener) {
        if (--this._eventsCount === 0)
          this._events = objectCreate(null);
        else {
          delete events[type];
          if (events.removeListener)
            this.emit('removeListener', type, list.listener || listener);
        }
      } else if (typeof list !== 'function') {
        position = -1;

        for (i = list.length - 1; i >= 0; i--) {
          if (list[i] === listener || list[i].listener === listener) {
            originalListener = list[i].listener;
            position = i;
            break;
          }
        }

        if (position < 0)
          return this;

        if (position === 0)
          list.shift();
        else
          spliceOne(list, position);

        if (list.length === 1)
          events[type] = list[0];

        if (events.removeListener)
          this.emit('removeListener', type, originalListener || listener);
      }

      return this;
    };

EventEmitter.prototype.removeAllListeners =
    function removeAllListeners(type) {
      var listeners, events, i;

      events = this._events;
      if (!events)
        return this;

      // not listening for removeListener, no need to emit
      if (!events.removeListener) {
        if (arguments.length === 0) {
          this._events = objectCreate(null);
          this._eventsCount = 0;
        } else if (events[type]) {
          if (--this._eventsCount === 0)
            this._events = objectCreate(null);
          else
            delete events[type];
        }
        return this;
      }

      // emit removeListener for all listeners on all events
      if (arguments.length === 0) {
        var keys = objectKeys(events);
        var key;
        for (i = 0; i < keys.length; ++i) {
          key = keys[i];
          if (key === 'removeListener') continue;
          this.removeAllListeners(key);
        }
        this.removeAllListeners('removeListener');
        this._events = objectCreate(null);
        this._eventsCount = 0;
        return this;
      }

      listeners = events[type];

      if (typeof listeners === 'function') {
        this.removeListener(type, listeners);
      } else if (listeners) {
        // LIFO order
        for (i = listeners.length - 1; i >= 0; i--) {
          this.removeListener(type, listeners[i]);
        }
      }

      return this;
    };

function _listeners(target, type, unwrap) {
  var events = target._events;

  if (!events)
    return [];

  var evlistener = events[type];
  if (!evlistener)
    return [];

  if (typeof evlistener === 'function')
    return unwrap ? [evlistener.listener || evlistener] : [evlistener];

  return unwrap ? unwrapListeners(evlistener) : arrayClone(evlistener, evlistener.length);
}

EventEmitter.prototype.listeners = function listeners(type) {
  return _listeners(this, type, true);
};

EventEmitter.prototype.rawListeners = function rawListeners(type) {
  return _listeners(this, type, false);
};

EventEmitter.listenerCount = function(emitter, type) {
  if (typeof emitter.listenerCount === 'function') {
    return emitter.listenerCount(type);
  } else {
    return listenerCount.call(emitter, type);
  }
};

EventEmitter.prototype.listenerCount = listenerCount;
function listenerCount(type) {
  var events = this._events;

  if (events) {
    var evlistener = events[type];

    if (typeof evlistener === 'function') {
      return 1;
    } else if (evlistener) {
      return evlistener.length;
    }
  }

  return 0;
}

EventEmitter.prototype.eventNames = function eventNames() {
  return this._eventsCount > 0 ? Reflect.ownKeys(this._events) : [];
};

// About 1.5x faster than the two-arg version of Array#splice().
function spliceOne(list, index) {
  for (var i = index, k = i + 1, n = list.length; k < n; i += 1, k += 1)
    list[i] = list[k];
  list.pop();
}

function arrayClone(arr, n) {
  var copy = new Array(n);
  for (var i = 0; i < n; ++i)
    copy[i] = arr[i];
  return copy;
}

function unwrapListeners(arr) {
  var ret = new Array(arr.length);
  for (var i = 0; i < ret.length; ++i) {
    ret[i] = arr[i].listener || arr[i];
  }
  return ret;
}

function objectCreatePolyfill(proto) {
  var F = function() {};
  F.prototype = proto;
  return new F;
}
function objectKeysPolyfill(obj) {
  var keys = [];
  for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k)) {
    keys.push(k);
  }
  return k;
}
function functionBindPolyfill(context) {
  var fn = this;
  return function () {
    return fn.apply(context, arguments);
  };
}

},{}],2:[function(require,module,exports){
// shim for using process in browser
var process = module.exports = {};

// cached from whatever global is present so that test runners that stub it
// don't break things.  But we need to wrap it in a try catch in case it is
// wrapped in strict mode code which doesn't define any globals.  It's inside a
// function because try/catches deoptimize in certain engines.

var cachedSetTimeout;
var cachedClearTimeout;

function defaultSetTimout() {
    throw new Error('setTimeout has not been defined');
}
function defaultClearTimeout () {
    throw new Error('clearTimeout has not been defined');
}
(function () {
    try {
        if (typeof setTimeout === 'function') {
            cachedSetTimeout = setTimeout;
        } else {
            cachedSetTimeout = defaultSetTimout;
        }
    } catch (e) {
        cachedSetTimeout = defaultSetTimout;
    }
    try {
        if (typeof clearTimeout === 'function') {
            cachedClearTimeout = clearTimeout;
        } else {
            cachedClearTimeout = defaultClearTimeout;
        }
    } catch (e) {
        cachedClearTimeout = defaultClearTimeout;
    }
} ())
function runTimeout(fun) {
    if (cachedSetTimeout === setTimeout) {
        //normal enviroments in sane situations
        return setTimeout(fun, 0);
    }
    // if setTimeout wasn't available but was latter defined
    if ((cachedSetTimeout === defaultSetTimout || !cachedSetTimeout) && setTimeout) {
        cachedSetTimeout = setTimeout;
        return setTimeout(fun, 0);
    }
    try {
        // when when somebody has screwed with setTimeout but no I.E. maddness
        return cachedSetTimeout(fun, 0);
    } catch(e){
        try {
            // When we are in I.E. but the script has been evaled so I.E. doesn't trust the global object when called normally
            return cachedSetTimeout.call(null, fun, 0);
        } catch(e){
            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error
            return cachedSetTimeout.call(this, fun, 0);
        }
    }


}
function runClearTimeout(marker) {
    if (cachedClearTimeout === clearTimeout) {
        //normal enviroments in sane situations
        return clearTimeout(marker);
    }
    // if clearTimeout wasn't available but was latter defined
    if ((cachedClearTimeout === defaultClearTimeout || !cachedClearTimeout) && clearTimeout) {
        cachedClearTimeout = clearTimeout;
        return clearTimeout(marker);
    }
    try {
        // when when somebody has screwed with setTimeout but no I.E. maddness
        return cachedClearTimeout(marker);
    } catch (e){
        try {
            // When we are in I.E. but the script has been evaled so I.E. doesn't  trust the global object when called normally
            return cachedClearTimeout.call(null, marker);
        } catch (e){
            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error.
            // Some versions of I.E. have different rules for clearTimeout vs setTimeout
            return cachedClearTimeout.call(this, marker);
        }
    }



}
var queue = [];
var draining = false;
var currentQueue;
var queueIndex = -1;

function cleanUpNextTick() {
    if (!draining || !currentQueue) {
        return;
    }
    draining = false;
    if (currentQueue.length) {
        queue = currentQueue.concat(queue);
    } else {
        queueIndex = -1;
    }
    if (queue.length) {
        drainQueue();
    }
}

function drainQueue() {
    if (draining) {
        return;
    }
    var timeout = runTimeout(cleanUpNextTick);
    draining = true;

    var len = queue.length;
    while(len) {
        currentQueue = queue;
        queue = [];
        while (++queueIndex < len) {
            if (currentQueue) {
                currentQueue[queueIndex].run();
            }
        }
        queueIndex = -1;
        len = queue.length;
    }
    currentQueue = null;
    draining = false;
    runClearTimeout(timeout);
}

process.nextTick = function (fun) {
    var args = new Array(arguments.length - 1);
    if (arguments.length > 1) {
        for (var i = 1; i < arguments.length; i++) {
            args[i - 1] = arguments[i];
        }
    }
    queue.push(new Item(fun, args));
    if (queue.length === 1 && !draining) {
        runTimeout(drainQueue);
    }
};

// v8 likes predictible objects
function Item(fun, array) {
    this.fun = fun;
    this.array = array;
}
Item.prototype.run = function () {
    this.fun.apply(null, this.array);
};
process.title = 'browser';
process.browser = true;
process.env = {};
process.argv = [];
process.version = ''; // empty string to avoid regexp issues
process.versions = {};

function noop() {}

process.on = noop;
process.addListener = noop;
process.once = noop;
process.off = noop;
process.removeListener = noop;
process.removeAllListeners = noop;
process.emit = noop;
process.prependListener = noop;
process.prependOnceListener = noop;

process.listeners = function (name) { return [] }

process.binding = function (name) {
    throw new Error('process.binding is not supported');
};

process.cwd = function () { return '/' };
process.chdir = function (dir) {
    throw new Error('process.chdir is not supported');
};
process.umask = function() { return 0; };

},{}],3:[function(require,module,exports){
'use strict';

var base = require('./core');

module.exports.read = function (path, callback) {
    if (callback !== undefined) {
        base.emitter.once(path, callback);
    }
    base.sendMessage({
        action: 'READ',
        path: path
    });
};

module.exports.write = function (path, value, callback) {
    if (callback !== undefined) {
        base.emitter.once(path, callback);
    }
    base.sendMessage({
        action: 'WRITE',
        path: path,
        payload: value
    });
};

module.exports.subscribe = function (path, callback) {
    if (base.getAuthentication() !== null) {
        console.log('attempt to subscribe a listener without initializing the COBI.js library');
    }
    base.emitter.addListener(path, callback);
};

module.exports.unsubscribe = function (path, callback) {
    if (callback !== undefined) {
        base.emitter.removeListener(path, callback);
    } else {
        base.emitter.removeAllListeners(path);
    }
};

},{"./core":5}],4:[function(require,module,exports){
'use strict';

var core = require('./core');

var action = require('./action');

var COBI = {
  specVersion: '0.44.0',
  // all elements prepended with `__` are for internal use only
  __emitter: core.emitter, // exposed here for backwards compatibility ... just in case
  __authentication: core.getAuthentication,

  /**
  * The Initialization function. You need to pass your apiKey from the COBI.Bike developer portal
  * in order to start receiving data
  * @name init
  * @example
  * COBI.init('token — can by any string right now')
  */
  init: function init(apiKey) {
    return core.init(apiKey, COBI.specVersion);
  },

  __authenticated: core.receiveAuthentication, // different name for backward compatibility

  __receiveMessage: core.receiveMessage,

  /** helper namespace for statical values which can be retrieved by
   * the COBI.Bike native app
   * @namespace parameters
   */
  parameters: {
    /** get the language specified by the user in the COBI.Bike app
     * @memberof parameters
     * @example
     * console.log(COBI.parameters.language())
     * // => 'en-US'
     */
    language: function language() {
      return core.getUrlParameter('language') || 'en-US';
    },

    /** get the current state that the webapp should reflect
     * @memberof parameters
     * @deprecated use {@link context} instead
     * @example
     * switch(COBI.parameters.state()) {
     *   case COBI.state.edit:
     *     // show something
     *   case COBI.state.overview:
     *     // show something else
     *   default:
     *     // ....
     * }
     */
    state: function state() {
      console.warn('COBI.parameters.state() is deprecated !! ... please use COBI.parameters.context() instead');
      return core.getUrlParameter('state') || COBI.state.experience;
    },

    /**
     *  get the current context that the module should reflect
     * @memberof parameters
     * @example
     * switch(COBI.parameters.context()) {
     *   case COBI.context.onRide:
     *     // show something
     *   case COBI.context.offRide:
     *     // show something else
     *   default:
     *     // ....
     * }
     */
    context: function context() {
      var context = core.getUrlParameter('context');
      var state = core.getUrlParameter('state');

      if (context != null) {
        return context;
      }

      if (state != null) {
        switch (state) {
          case COBI.state.experience:
            return COBI.context.onRide;
          case COBI.state.edit:
            return COBI.context.offRideSettings;
        }
      }
      // default
      return COBI.context.offRide;
    },

    /** get the SDK version of the native app
     * @memberof parameters
     * @example console.log(COBI.parameters.nativeSdkVersion())
     * // => '0.34.1'
     */
    nativeSdkVersion: function nativeSdkVersion() {
      return core.getUrlParameter('version') || COBI.specVersion;
    }
  },

  /**
   * The state definitions helps you manage the user experience on your module.
   * You should adapt your webapp content based on the state changes.
   * Whenever a WebApp is loaded it will receive the respective required Module State via URL
   * @namespace state
   * @deprecated use {@link context} instead
   * @example
   * switch(COBI.parameters.state()) {
   *   case COBI.state.edit:
   *     // show something
   *   case COBI.state.overview:
   *     // show something else
   *   default:
   *     // ....
   * }
   */
  state: {
    /**
     * The Module Experience State is the main content, i.e. experience of a WebApp. The user will spend
     * most of his time in this state of the WebApp during a ride
     * @memberof state
     */
    experience: 'experience',
    /**
     * The purpose of the Edit State of a WebApp Module is mainly to give the user instructions
     * on how to use the WebApp in form of a (standardised) Manual.
     * @memberof state
     */
    edit: 'edit',

    /**
     * The module overview state is when the webapp is opened without riding with a COBI.Bike Hub.
     * Therefore no thumb controller interaction nor bike values should be expected to flow to the
     * webapp
     * @memberof state
     */
    overview: 'overview'
  },

  /**
   * The context definitions helps you manage the user experience on your module.
   * You should adapt your webapp content based on the context changes.
   * The context parameter is passed through the URL parameters that a module receives on load
   * @namespace context
   * @example
   * switch(COBI.parameters.context()) {
   *   case COBI.context.onRide:
   *     // show something
   *   case COBI.context.offRide:
   *     // show something else
   *   default:
   *     // ....
   * }
   */
  context: {
    /**
     * Set during the riding phase. Show information relevant during the ride (e.g. live metrics) and offer
     * interaction that is optimized for the short attention time while riding.
     * @memberof context
     */
    onRide: 'onRide',

    /**
     * Set in the pre- and post-riding phase. Allow the user to prepare his next ride, give him an overview
     * of what he can expect during the riding phase or information about his last ride or offer services
     * that are not relevant during the riding phase (e.g. maintenance)
     * @memberof context
     */
    offRide: 'offRide',

    /**
     * Set when the user accesses the settings during the riding phase. Allow the user to quickly adjust
     * important settings while he is on the go. Those settings should be targeted to `COBI.context.onRide`
     * experience.
     * @memberof context
     */
    onRideSettings: 'onRideSettings',

    /**
     * Set when the user accesses the settings during the pre- and post-riding phase. Offer more in-depth
     * settings that are either targeted to `COBI.context.offRide` or both experiences
     * (depending on how your module is structured)
     * @memberof context
     */
    offRideSettings: 'offRideSettings'
  },

  /**
   * The app represents the main COBI.Bike user interface running on a mobile device. It can be used to get information about and to manipulate selected UI states and to request and send information to the user.
   * @namespace app
   */
  app: {
    /**
     * The app color theme. This can be used to match the appearance of the COBI.Bike app in, for example, DevKit modules.
     * @memberof app
     */
    theme: {
      /**
       * @memberof app.theme
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('app/theme', callback);
      },

      /**
       * @memberof app.theme
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('app/theme', callback);
      }
    },
    /**
     * Let's the phone speak out the provided text using the built in text to speech engine. The language parameter is ignored for now and the system language is used.
     * @memberof app
     */
    textToSpeech: {
      /**
       * @memberof app.textToSpeech
       */
      write: function write(request, callback) {
        return action.write('app/textToSpeech', request, callback);
      }
    },
    /**
     * Adds an entry to the web-browser read later list.
     * @memberof app
     */
    readLater: {
      /**
       * @memberof app.readLater
       */
      write: function write(request, callback) {
        return action.write('app/readLater', request, callback);
      }
    },
    /**
     * The language the phone is set to. Used to localize all content presented to the user. Represented according to RFC 5646. Example: "en-US", "de-CH".
     * @memberof app
     */
    language: {
      /**
       * @memberof app.language
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('app/language', callback);
      },

      /**
       * @memberof app.language
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('app/language', callback);
      }
    },
    /**
     * Prompts the user to pick a contact from the list of favorite contacts. The selected contact will be returned. Nothing is returned if the user cancelled the contact picker.
     * @memberof app
     */
    contact: {
      /**
       * @memberof app.contact
       */
      read: function read(callback) {
        return action.read('app/contact', callback);
      }
    },
    /**
     * If the user is allowed to interact with the user interface via touch. This status is based on the fact if he is currently riding or standing.
      When disabled, all touch-interactive elements should be removed from the screen and the amount of information should be reduced to avoid distracting the user while he/she is riding.
      When enabled, a navigation bar is shown at the top of the screen which reduces the vertical size available to modules.
     * @memberof app
     */
    touchInteractionEnabled: {
      /**
       * @memberof app.touchInteractionEnabled
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('app/touchInteractionEnabled', callback);
      },

      /**
       * @memberof app.touchInteractionEnabled
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('app/touchInteractionEnabled', callback);
      }
    },
    /**
     * The last known hub location. The location is updated sporadically while the hub is connected. When it gets disconnected, the current location is stored. If you want to receive continous location updates, use mobile.location.
     * @memberof app
     */
    hubLocation: {
      /**
       * @memberof app.hubLocation
       */
      read: function read(callback) {
        return action.read('app/hubLocation', callback);
      },
      /**
       * @memberof app.hubLocation
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('app/hubLocation', callback);
      },

      /**
       * @memberof app.hubLocation
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('app/hubLocation', callback);
      }
    },
    /**
     * If the clock overlay is shown on top of module experiences. By default, the clock is visible. When written immediately on module start, the clock is not shown at all.
     * @memberof app
     */
    clockVisible: {
      /**
       * @memberof app.clockVisible
       */
      write: function write(request, callback) {
        return action.write('app/clockVisible', request, callback);
      }
    },
    /**
     * Reflects if it is dark outside. This is decided based on the time of the day. You may want to show a dark theme when it is dark to improve the user experience.
     * @memberof app
     */
    isDark: {
      /**
       * @memberof app.isDark
       */
      read: function read(callback) {
        return action.read('app/isDark', callback);
      },
      /**
       * @memberof app.isDark
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('app/isDark', callback);
      },

      /**
       * @memberof app.isDark
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('app/isDark', callback);
      }
    },
    /**
     * If the user's hub is connected to the app
     * @memberof app
     */
    isHubConnected: {
      /**
       * @memberof app.isHubConnected
       */
      read: function read(callback) {
        return action.read('app/isHubConnected', callback);
      },
      /**
       * @memberof app.isHubConnected
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('app/isHubConnected', callback);
      },

      /**
       * @memberof app.isHubConnected
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('app/isHubConnected', callback);
      }
    }
  },
  /**
   * The hub is the COBI.Bike device the smartphone is mounted on. When connected to the smartphone, it makes information about it's state and external input available and allows to trigger certain actions. Before usage, a hub has to be activated via the COBI.Bike API (done during the setup on the phone).
   * @namespace hub
   */
  hub: {
    /**
     * If the e-bike driver is available, up and running. When this is reported, you can be sure to be on an e-bike and can expect e-bike sepecific information (see motor).
     * @memberof hub
     */
    motorInterfaceReady: {
      /**
       * @memberof hub.motorInterfaceReady
       */
      read: function read(callback) {
        return action.read('hub/motorInterfaceReady', callback);
      },
      /**
       * @memberof hub.motorInterfaceReady
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('hub/motorInterfaceReady', callback);
      },

      /**
       * @memberof hub.motorInterfaceReady
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('hub/motorInterfaceReady', callback);
      }
    },
    /**
     * If the bell sound is curently played. When the user presses the bell button, it changes to true and back to false when it finished playing.
     * @memberof hub
     */
    bellRinging: {
      /**
       * @memberof hub.bellRinging
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('hub/bellRinging', callback);
      },

      /**
       * @memberof hub.bellRinging
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('hub/bellRinging', callback);
      }
    },
    /**
     * Triggered when user presses a button on the connected thumb controller. Which action depends on the thumb controller mapping that is currently active (see devkit.overrideThumbControllerMapping).
      The actions are used to control the mobile app. Only the following actions are made available to DevKit modules: UP, DOWN, LEFT, RIGHT, SELECT.
     * @memberof hub
     */
    externalInterfaceAction: {
      /**
       * @memberof hub.externalInterfaceAction
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('hub/externalInterfaceAction', callback);
      },

      /**
       * @memberof hub.externalInterfaceAction
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('hub/externalInterfaceAction', callback);
      }
    },
    /**
     * The current ambient light state. Based on the value measured by the hubs ambient light sensor, one of the categories is reported.
     * @memberof hub
     */
    ambientLightState: {
      /**
       * @memberof hub.ambientLightState
       */
      read: function read(callback) {
        return action.read('hub/ambientLightState', callback);
      },
      /**
       * @memberof hub.ambientLightState
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('hub/ambientLightState', callback);
      },

      /**
       * @memberof hub.ambientLightState
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('hub/ambientLightState', callback);
      }
    }
  },
  /**
   *
   * @namespace battery
   */
  battery: {
    /**
     * Represents the external battery condition (Hub or E-bike battery respectively). I.e. charging level in % and status.
     * @memberof battery
     */
    state: {
      /**
       * @memberof battery.state
       */
      read: function read(callback) {
        return action.read('battery/state', callback);
      },
      /**
       * @memberof battery.state
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('battery/state', callback);
      },

      /**
       * @memberof battery.state
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('battery/state', callback);
      }
    }
  },
  /**
   * The Motor Channel transmits data related to an eBike. The channel only transmits that data if the Hub is actually attached to an eBike. The Bus will not write or read any data until the *Ebike System Available* Property of the [Main Bus]((#message-bus-channels---main) is true.
   * @namespace motor
   */
  motor: {
    /**
     * Defines the total traveled distance by the system in km.
     * @memberof motor
     */
    distance: {
      /**
       * @memberof motor.distance
       */
      read: function read(callback) {
        return action.read('motor/distance', callback);
      },
      /**
       * @memberof motor.distance
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('motor/distance', callback);
      },

      /**
       * @memberof motor.distance
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('motor/distance', callback);
      }
    },
    /**
     * Utilization level in % of the currently set drive mode engine support.
     * @memberof motor
     */
    assistanceIndicator: {
      /**
       * @memberof motor.assistanceIndicator
       */
      read: function read(callback) {
        return action.read('motor/assistanceIndicator', callback);
      },
      /**
       * @memberof motor.assistanceIndicator
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('motor/assistanceIndicator', callback);
      },

      /**
       * @memberof motor.assistanceIndicator
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('motor/assistanceIndicator', callback);
      }
    },
    /**
     * Defines the range value in km of the eBike. That means which distance you can travel with eBike engine support.
     * @memberof motor
     */
    range: {
      /**
       * @memberof motor.range
       */
      read: function read(callback) {
        return action.read('motor/range', callback);
      },
      /**
       * @memberof motor.range
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('motor/range', callback);
      },

      /**
       * @memberof motor.range
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('motor/range', callback);
      }
    },
    /**
     * Defines the number of supported drive modes by the eBike. I.e. 5.
     * @memberof motor
     */
    supportedDriveModes: {
      /**
       * @memberof motor.supportedDriveModes
       */
      read: function read(callback) {
        return action.read('motor/supportedDriveModes', callback);
      },
      /**
       * @memberof motor.supportedDriveModes
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('motor/supportedDriveModes', callback);
      },

      /**
       * @memberof motor.supportedDriveModes
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('motor/supportedDriveModes', callback);
      }
    },
    /**
     * Defines the eBike drive mode of the specific vendor protocol. In ascending order. Depending on the Supported Drive Modes
     * @memberof motor
     */
    driveMode: {
      /**
       * @memberof motor.driveMode
       */
      read: function read(callback) {
        return action.read('motor/driveMode', callback);
      },
      /**
       * @memberof motor.driveMode
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('motor/driveMode', callback);
      },

      /**
       * @memberof motor.driveMode
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('motor/driveMode', callback);
      }
    },
    /**
     * Provides information about the maintenance status of the bike; at which point in time and/or distance it is needed
     * @memberof motor
     */
    nextService: {
      /**
       * @memberof motor.nextService
       */
      read: function read(callback) {
        return action.read('motor/nextService', callback);
      },
      /**
       * @memberof motor.nextService
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('motor/nextService', callback);
      },

      /**
       * @memberof motor.nextService
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('motor/nextService', callback);
      }
    }
  },
  /**
   * Channel for reporting hardware-related values
   * @namespace mobile
   */
  mobile: {
    /**
     * A data class representing a geographic location. A location can consist of a latitude, longitude, timestamp and other information such as bearing, altitude and velocity.
     * @memberof mobile
     */
    location: {
      /**
       * @memberof mobile.location
       */
      read: function read(callback) {
        return action.read('mobile/location', callback);
      },
      /**
       * @memberof mobile.location
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('mobile/location', callback);
      },

      /**
       * @memberof mobile.location
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('mobile/location', callback);
      }
    },
    /**
     * Represents the direction the device is hold in degrees, where 0 degrees is true North
     * @memberof mobile
     */
    heading: {
      /**
       * @memberof mobile.heading
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('mobile/heading', callback);
      },

      /**
       * @memberof mobile.heading
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('mobile/heading', callback);
      }
    },
    /**
     * If the location and the heading are available (depending on the device capabilities and the user confirmation)
     * @memberof mobile
     */
    locationAvailability: {
      /**
       * @memberof mobile.locationAvailability
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('mobile/locationAvailability', callback);
      },

      /**
       * @memberof mobile.locationAvailability
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('mobile/locationAvailability', callback);
      }
    }
  },
  /**
   * Refers to all navigation related information calculated or provided by the navigation engine on the COBI.Bike app
   * @namespace navigationService
   */
  navigationService: {
    /**
     * The calculated navigation route. Drawn on the map during a ride and used for turn-by-turn advices. The route is not updated while riding — live information is available via the navigation service eta and distance to destination. To request a route to a different destination write to the navigation service control.
     * @memberof navigationService
     */
    route: {
      /**
       * @memberof navigationService.route
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('navigationService/route', callback);
      },

      /**
       * @memberof navigationService.route
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('navigationService/route', callback);
      }
    },
    /**
     * Estimated time of arrival as timestamp: seconds since 00:00:00 UTC on 1 January 1970
     * @memberof navigationService
     */
    eta: {
      /**
       * @memberof navigationService.eta
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('navigationService/eta', callback);
      },

      /**
       * @memberof navigationService.eta
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('navigationService/eta', callback);
      }
    },
    /**
     * Distance remaining until the set destination in meters
     * @memberof navigationService
     */
    distanceToDestination: {
      /**
       * @memberof navigationService.distanceToDestination
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('navigationService/distanceToDestination', callback);
      },

      /**
       * @memberof navigationService.distanceToDestination
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('navigationService/distanceToDestination', callback);
      }
    },
    /**
     * Defines the current status of the navigation engine
     * @memberof navigationService
     */
    status: {
      /**
       * @memberof navigationService.status
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('navigationService/status', callback);
      },

      /**
       * @memberof navigationService.status
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('navigationService/status', callback);
      }
    },
    /**
     * Controls the navigation service route and status. When triggering `plan` or `start` a new route is calculated. When the calculation is finished, the new route is published as the navigation service route.
     * @memberof navigationService
     */
    control: {
      /**
       * @memberof navigationService.control
       */
      write: function write(request, callback) {
        return action.write('navigationService/control', request, callback);
      }
    }
  },
  /**
   * Channel for reporting user-related information setup in the COBI.Bike app
   * @namespace user
   */
  user: {
    /**
     *
     * @memberof user
     */
    temperatureUnit: {
      /**
       * @memberof user.temperatureUnit
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('user/temperatureUnit', callback);
      },

      /**
       * @memberof user.temperatureUnit
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('user/temperatureUnit', callback);
      }
    },
    /**
     *
     * @memberof user
     */
    lengthUnit: {
      /**
       * @memberof user.lengthUnit
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('user/lengthUnit', callback);
      },

      /**
       * @memberof user.lengthUnit
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('user/lengthUnit', callback);
      }
    }
  },
  /**
   * Channel for reporting bike-related configuration parameters
   * @namespace bike
   */
  bike: {
    /**
     * The type of bike you are using
     * @memberof bike
     */
    type: {
      /**
       * @memberof bike.type
       */
      read: function read(callback) {
        return action.read('bike/type', callback);
      },
      /**
       * @memberof bike.type
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('bike/type', callback);
      },

      /**
       * @memberof bike.type
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('bike/type', callback);
      }
    }
  },
  /**
   * Channel for reporting information calculated and provided by the intelligence library in the COBI.Bike app [More information](https://github.com/cobi-bike/COBI-Intelligence)
   * @namespace rideService
   */
  rideService: {
    /**
     * Speed provided by the intelligence library used in the app (in m/s)
     * @memberof rideService
     */
    speed: {
      /**
       * @memberof rideService.speed
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('rideService/speed', callback);
      },

      /**
       * @memberof rideService.speed
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('rideService/speed', callback);
      }
    },
    /**
     * The expressed power from the user. A heart-rate sensor is the primary source for calculating this, but if it is missing - current bike's speed, road grade, etc. are used. Also the biker's body-metrics are always taken into account. The value is in Watts
     * @memberof rideService
     */
    userPower: {
      /**
       * @memberof rideService.userPower
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('rideService/userPower', callback);
      },

      /**
       * @memberof rideService.userPower
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('rideService/userPower', callback);
      }
    },
    /**
     * If the user power is available from any sensor
     * @memberof rideService
     */
    userPowerAvailability: {
      /**
       * @memberof rideService.userPowerAvailability
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('rideService/userPowerAvailability', callback);
      },

      /**
       * @memberof rideService.userPowerAvailability
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('rideService/userPowerAvailability', callback);
      }
    },
    /**
     * Heart Rate  — in bpm. Unifies all the sources for heart rate. When heart rate is reported from different sensors, only the most accurate one is reported on this property.
     * @memberof rideService
     */
    heartRate: {
      /**
       * @memberof rideService.heartRate
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('rideService/heartRate', callback);
      },

      /**
       * @memberof rideService.heartRate
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('rideService/heartRate', callback);
      }
    },
    /**
     * If the heart rate is available from any sensor
     * @memberof rideService
     */
    heartRateAvailability: {
      /**
       * @memberof rideService.heartRateAvailability
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('rideService/heartRateAvailability', callback);
      },

      /**
       * @memberof rideService.heartRateAvailability
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('rideService/heartRateAvailability', callback);
      }
    },
    /**
     * Cadence — in rpm. Unifies all the sources for cadence. When cadence is reported from different sensors, only the most accurate one is reported on this property.
     * @memberof rideService
     */
    cadence: {
      /**
       * @memberof rideService.cadence
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('rideService/cadence', callback);
      },

      /**
       * @memberof rideService.cadence
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('rideService/cadence', callback);
      }
    },
    /**
     * If the cadence is available from any sensor
     * @memberof rideService
     */
    cadenceAvailability: {
      /**
       * @memberof rideService.cadenceAvailability
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('rideService/cadenceAvailability', callback);
      },

      /**
       * @memberof rideService.cadenceAvailability
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('rideService/cadenceAvailability', callback);
      }
    }
  },
  /**
   * Channel that describes a bicycle tour as a whole. It contains all statistical data that is available for a tour as well as their status. When a tour is ongoing, the statistical values are updated based on the live values provided by the ride_channel.
    [concept](https://drive.google.com/drive/folders/0B7qZU5q_H2aVcG14QThsLUNwMW8)
   * @namespace tourService
   */
  tourService: {
    /**
     * Total amount of calories burnt - in kcal.
     * @memberof tourService
     */
    calories: {
      /**
       * @memberof tourService.calories
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('tourService/calories', callback);
      },

      /**
       * @memberof tourService.calories
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('tourService/calories', callback);
      }
    },
    /**
     * The total aggregated ascend - in meters.
     * @memberof tourService
     */
    ascent: {
      /**
       * @memberof tourService.ascent
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('tourService/ascent', callback);
      },

      /**
       * @memberof tourService.ascent
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('tourService/ascent', callback);
      }
    },
    /**
     * The total distance travelled while moving - in meters.
     * @memberof tourService
     */
    ridingDistance: {
      /**
       * @memberof tourService.ridingDistance
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('tourService/ridingDistance', callback);
      },

      /**
       * @memberof tourService.ridingDistance
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('tourService/ridingDistance', callback);
      }
    },
    /**
     * The total movement duration — in seconds.
     * @memberof tourService
     */
    ridingDuration: {
      /**
       * @memberof tourService.ridingDuration
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('tourService/ridingDuration', callback);
      },

      /**
       * @memberof tourService.ridingDuration
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('tourService/ridingDuration', callback);
      }
    },
    /**
     * The average speed — in meters / second.
     * @memberof tourService
     */
    averageSpeed: {
      /**
       * @memberof tourService.averageSpeed
       */
      subscribe: function subscribe(callback) {
        return action.subscribe('tourService/averageSpeed', callback);
      },

      /**
       * @memberof tourService.averageSpeed
       */
      unsubscribe: function unsubscribe(callback) {
        return action.unsubscribe('tourService/averageSpeed', callback);
      }
    }
  },
  /**
   * Used by the COBI.js library for developing COBI.Bike modules, the first smart connected biking system.
   * @namespace devkit
   */
  devkit: {
    /**
     * Quits the webapp and returns to the app selector
     * @memberof devkit
     */
    close: {
      /**
       * @memberof devkit.close
       */
      write: function write(request, callback) {
        return action.write('devkit/close', request, callback);
      }
    },
    /**
     * Useful whenever a webapp wants to override the current thumb controller mapping and instead use all thumb controller action
     * @memberof devkit
     */
    overrideThumbControllerMapping: {
      /**
       * @memberof devkit.overrideThumbControllerMapping
       */
      write: function write(request, callback) {
        return action.write('devkit/overrideThumbControllerMapping', request, callback);
      }
    }
  }
};

module.exports = COBI;

},{"./action":3,"./core":5}],5:[function(require,module,exports){
(function (process,global){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var EventEmitter = require('events');

var emitter = new EventEmitter();
var authentication = null; // internal state

function context() {
    if (global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cobiAuth && global.webkit.messageHandlers.cobiAuth.postMessage) {
        return 'ios';
    }

    if (global.cobijsAndroidImpl && global.cobijsAndroidImpl.messageFromJs) {
        return 'android';
    }

    if ((typeof process === 'undefined' ? 'undefined' : _typeof(process)) === 'object' && process.toString() === '[object process]') {
        return 'node';
    }
}

module.exports.emitter = emitter; // exposed for backwards compatibility
module.exports.getAuthentication = function () {
    return authentication;
};

module.exports.init = function (apiKey, specVersion) {
    var currentContext = context();

    // if runtime reached this point it is most likely that we are
    // in a desktop browser (simulator). Therefore we just timeout the
    // request until the simulator kicks in and monkey patches
    // the webkit code
    if (currentContext != null) {
        emitter.emit('auth', { token: apiKey, version: specVersion });
    } else {
        var wait = 500; // milliseconds
        console.log('COBI.init failed. Retrying in ' + wait + ' milliseconds');
        setTimeout(function () {
            return module.exports.init(apiKey, specVersion);
        }, wait);
    }
};

module.exports.receiveAuthentication = function (result) {
    if (result && result.confirmed && result.apiKey) {
        console.log('COBI.js authenticated');
        authentication = result;
    } else {
        console.error('Invalid COBI.js authentication: ' + JSON.stringify(result));
    }
};

module.exports.receiveMessage = function (msg) {
    // for the time being we just wrap the emit function
    // in the future we might request the COBI.js lib
    // to respond to read/write messages
    var timestamp = new Date(msg.timestamp || Date.now());
    emitter.emit(msg.path, msg.payload, timestamp);
};

module.exports.sendMessage = function (msg) {
    var wait = 500; // milliseconds
    var currentContext = context();
    if (authentication && authentication.apiKey === null) {
        // the user has not authenticated, retry in a while hoping that he does in that time
        console.log('COBI.js message couldn\'t not be sent: missing authentication key. Retrying in ' + wait + ' milliseconds');
        return setTimeout(function () {
            return module.exports.sendMessage(msg);
        }, wait);
    }

    if (currentContext != null) {
        emitter.emit('shell', msg);
    } else {
        console.log('COBI.js could not send your message. Retrying in ' + wait + ' milliseconds');
        return setTimeout(function () {
            return module.exports.sendMessage(msg);
        }, wait); // ms
    }
};

function urlParameterRegex(name) {
    return new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)');
}
// Helper function to fetch the value of a specific URL parameter with
// a provided name
module.exports.getUrlParameter = function (name) {
    var regex = urlParameterRegex(name);
    var result = regex.exec(global.location.search);
    if (result !== null) {
        return result[1].replace(/\+/g, '%20');
    }
    return null;
};

emitter.on('auth', function (message) {
    // iOS hooks
    if (global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cobiAuth && global.webkit.messageHandlers.cobiAuth.postMessage) {
        return global.webkit.messageHandlers.cobiAuth.postMessage(message);
    }

    // Android hooks
    if (global.cobijsAndroidImpl && global.cobijsAndroidImpl.messageFromJs) {
        return global.cobijsAndroidImpl.messageFromJs('cobiAuth', JSON.stringify(message));
    }
});

emitter.on('shell', function (message) {
    // iOS hooks
    if (global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cobiAuth && global.webkit.messageHandlers.cobiAuth.postMessage) {
        return global.webkit.messageHandlers.cobiShell.postMessage(message);
    }

    // Android hooks
    if (global.cobijsAndroidImpl && global.cobijsAndroidImpl.messageFromJs) {
        return global.cobijsAndroidImpl.messageFromJs('cobiShell', JSON.stringify(message));
    }
});

}).call(this,require('_process'),typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"_process":2,"events":1}]},{},[4])(4)
});
